import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import { useNavigate } from 'react-router-dom';


  

function AppDrawer() {
    const navigate = useNavigate();

    const handleNavItemClick = (item) => {
        console.log(item);
        
        // Add routing logic here
        if (item === 'AIAE Data') {
            navigate('/Dashboard');
        }
        if (item === 'ETF Tactical Asset Allocation Rank') {
            navigate('/TAAETF');
        }
    
    };

    const drawerList = () => (
        <Box sx={{ overflow: 'auto' }}>
            <List>
            {['AIAE Data', 'ETF Tactical Asset Allocation Rank'].map((text, index) => (
                <ListItem button key={text} onClick={() => handleNavItemClick(text)}>
                <ListItemIcon>{index % 2 === 0 ? <ShowChartIcon /> : <FormatListNumberedIcon />}</ListItemIcon>
                <ListItemText primary={text} />
                </ListItem>
            ))}
            </List>
        </Box>
        );

    return (
   
        <Drawer variant="permanent" sx={{
            width: 250,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: 250, boxSizing: 'border-box' },
          }}>
        <Toolbar />
        
        {drawerList()}
        
        </Drawer>

    );
}

export default AppDrawer;


