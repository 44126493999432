import { CognitoUserPool } from 'amazon-cognito-identity-js';
const CognitoConfig = require('../config/CognitoConfig');

export function getAccessToken() {
  return new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool({
      UserPoolId: CognitoConfig.UserPoolId,
      ClientId: CognitoConfig.ClientId,
    });

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error(err);
          reject(err);
          return;
        }

        if (session.isValid()) {
          const accessToken = session.getAccessToken().getJwtToken();
          // console.log('Session is valid.');
          //console.log(idToken);
          resolve(accessToken);
        } else {
          console.log('Session is not valid.');
          reject(new Error('Session is not valid.'));
        }
      });
    } else {
      console.log('Access token not found in local storage.');
      reject(new Error('Access token not found in local storage.'));
    }
  });
}
