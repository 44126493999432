
import client from '../../apolloClient';
import { gql } from '@apollo/client';

const DELETE_GOAL = gql`

    mutation DeleteFinancialGoal($clientId: String!, $financialGoalId: String!) { 
        deleteFinancialGoal(clientId: $clientId, financialGoalId: $financialGoalId) 
    }

`;


export const deleteGoal = async (clientId, financialGoalId) => {

    const { data } = await client.mutate({
        mutation: DELETE_GOAL,
        variables: { clientId, financialGoalId },
      });
    
    // console.log("GraphQL Data: ", data);
    await client.resetStore();
  

  
    return data;
  };
  