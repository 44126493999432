import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { TextField, Button, Typography, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CognitoConfig from '../../config/CognitoConfig';




const Verify = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const username = localStorage.getItem('username');
  // const email = localStorage.getItem('email');

  const [formState, setFormState] = useState({
    code: '',
  });

  const handleFormChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleVerify = async (e) => {
    e.preventDefault();

    const userPool = new CognitoUserPool({
      UserPoolId: CognitoConfig.UserPoolId,
      ClientId: CognitoConfig.ClientId,
    });

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(formState.code, true, (err, result) => {
      if (err) {
        console.log(err);
        setErrorMessage(err.message);
        return;
      }
      console.log('verification successful');
      

      navigate('/SignIn');
    });
  };

  const handleResendCode = () => {
    setSuccessMessage(''); // Clear the success message

    const userPool = new CognitoUserPool({
      UserPoolId: CognitoConfig.UserPoolId,
      ClientId: CognitoConfig.ClientId,
    });

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    
    cognitoUser.resendConfirmationCode((err) => {
      if (err) {
        console.log(err);
        setErrorMessage(err.message);
        return;
      }
      
      

      console.log('Verification code resent successfully');
      setSuccessMessage('Verification code resent successfully. Please check your email.');

    });
  };

  return (

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* Render Alert component when there's an error message */}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          {/* Render Alert component when there's a success message */}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}


          <Typography component="h1" variant="h5">
            Verification Code
          </Typography>
          <Box component="form" onSubmit={handleVerify} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="code"
              label="Verification Code"
              name="code"
              autoComplete="code"
              autoFocus
              value={formState.code}
              onChange={handleFormChange}
            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Verify
            </Button>

            <Typography component="h1" variant="h5">
              <Link href="/SignIn" variant="body2">
                {'Already have an account? Sign In'}
              </Link>
            </Typography>
            {/* Allow the user the ability to resend code using a link and alert them if successful */}
            <Typography component="h1" variant="h5">
              <Link href="#" variant="body2" onClick={handleResendCode}>
                {'Resend Code'}
              </Link>
            </Typography>

        



          </Box>
        </Box>
      </Container>

  );
};

export default Verify;
