
import client from '../../apolloClient';
import { gql } from '@apollo/client';

const UPDATE_GOAL = gql`

mutation UpdateFinancialGoal($clientId: String!, $financialGoalId: String!, $input: UpdateFinancialGoalInput!) { 
    updateFinancialGoal(clientId: $clientId, financialGoalId: $financialGoalId, input: $input) { clientId financialGoalId }
}

`;


export const updateGoal = async (clientId, financialGoalId, input) => {
    if (!input) {
        throw new Error("Input required.");
      }
      // console.log("Input: " + input);

    const { data } = await client.mutate({
        mutation: UPDATE_GOAL,
        variables: { clientId, financialGoalId, input },
      });
    
    // console.log("GraphQL Data: ", data);
    await client.resetStore();
  

  
    return data;
  };
  