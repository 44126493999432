
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name Required'),
    cls: Yup.string().required('Type Required'),
    targetValue: Yup.number()
      .required('Required')
      .positive('Must be positive'),
    targetYear: Yup.string()
      .required('Required')
      .length(4, 'Target Year must be exactly 4 digits')
      .matches(/^\d{4}$/, 'Target Year must only contain numbers'),
    initialContribution: Yup.number()
      .required('Required'),
    monthlyContribution: Yup.number()
      .required('Required')
      .positive('Must be positive'),
    retirementAge: Yup.number()
      .when('cls', {
        is: 'RetirementGoal', // only require when goal type is retirement
        then: () => Yup.number('Must be a Number')
          .required('Required')
          .positive('Must be positive'),
      }),
    
    degreeType: Yup.string()
      .when('cls', {
          is: 'EducationGoal', // only require when goal type is education
          then: () => Yup.string()
            .required('Required'),
        }),

    schoolName: Yup.string()
      .when('cls', {
          is: 'EducationGoal', // only require when goal type is education
          then: () => Yup.string()
            .required('Required'),
        }),
    weddingType: Yup.string()
      .when('cls', {
          is: 'WeddingGoal', // only require when goal type is wedding
          then: () =>  Yup.string()
            .required('Required'),
        }),
    realEstateType: Yup.string()
        .when('cls', {
            is: 'RealEstateGoal', // only require when goal type is real estate
            then: () =>  Yup.string()
                .required('Required'),
        }),
    realEstateLocation: Yup.string()
        .when('cls', {
            is: 'RealEstateGoal', // only require when goal type is real estate
            then: () =>  Yup.string()
                .required('Required'),
        }),
    realEstateValue: Yup.number()
        .when('cls', {
            is: 'RealEstateGoal', // only require when goal type is real estate
            then: () =>  Yup.number()
                .required('Required')
                .positive('Must be positive'),
        }),
    downPayment: Yup.number()
        .when('cls', {
            is: (cls) => ['CarGoal'].includes(cls),
            then: () =>  Yup.number()
                .required('Required')
                .positive('Must be positive'),
        }),

    mortgageTerm: Yup.number()
        .when('cls', {
            is: 'RealEstateGoal', // only require when goal type is real estate
            then: () => Yup.number()
                .required('Required')
                .positive('Must be positive'),
        }),
    mortgageRate: Yup.number()
        .when('cls', {
            is: 'RealEstateGoal', // only require when goal type is real estate
            then: () =>  Yup.number()
                .required('Required')
                .positive('Must be positive'),
        }),
    destination: Yup.string()
        .when('cls', {
            is: 'VacationGoal', // only require when goal type is vacation
            then: () =>  Yup.string()
                .required('Required'),
        }),
    durationDays: Yup.number()
        .when('cls', {
            is: 'VacationGoal', // only require when goal type is vacation
            then: () =>  Yup.number()
                .required('Required')
                .positive('Must be positive'),
        }),
    carType: Yup.string()
        .when('cls', {
            is: 'CarGoal', // only require when goal type is car
            then: () =>  Yup.string()
                .required('Required'),
        }),
    carValue: Yup.number()
        .when('cls', {
            is: 'CarGoal', // only require when goal type is car
            then: () =>  Yup.number()
                .required('Required')
                .positive('Must be positive'),
        }),
    loanTerm: Yup.number()
        .when('cls', {
            is: 'CarGoal', // only require when goal type is car
            then: () =>  Yup.number()
                .required('Required')
                .positive('Must be positive'),
        }),
    loanRate: Yup.number()
        .when('cls', {
            is: 'CarGoal', // only require when goal type is car
            then: () =>  Yup.number()
                .required('Required')
                .positive('Must be positive'),
        }),
    description: Yup.string()
        .when('cls', {
            is: 'OtherGoal', // only require when goal type is other
            then: () =>  Yup.string()
                .required('Required'),
        }),
    
});

export default validationSchema;