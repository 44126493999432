import client  from '../../apolloClient';
import { gql } from '@apollo/client';

const GET_CLIENT = gql`
  query GetClient($username: String!) {
    client(username: $username) {
      clientId
      username
      email
      firstName
      lastName
      phone
      streetAddress
      city
      state
      zip
      birthdate
    }
  }
`;

export const getClientByUsername = async (username) => {
    


    const { data } = await client.query({
        query: GET_CLIENT,
        variables: { username },
        skip: !username,
    });

    // console.log('GraphQL Data: ', data)
    
    return data;
}