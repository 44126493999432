import React, { useEffect, useState } from 'react'; 
import AppHeader from "../../components/AppHeader";
import AppDrawer from './DashboardDrawer';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function TAAETF() {
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [data, setData] = useState(null); // Initialize data state

  useEffect(() => {
    getTAAData();
  }, []);

  const getTAAData = async () => {
    const api_call = await fetch('https://api.ice-ix.net/v1-taa-etf/taa-etf', {
      mode: 'cors',
    })
    const fetchedData = await api_call.json();
    console.log(fetchedData);
    setData(fetchedData); // Set fetched data to state variable

    setLoading(false); // Set loading to false when data is fetched
  }

  const renderTableData = () => {
    return Object.keys(data.results).map(key => {
      const item = data.results[key];
      return (
        <TableRow key={key}>
          <TableCell component="th" scope="row">{key}</TableCell> {/* Display the ticker symbol */}
          <TableCell align="right">{item.name}</TableCell>
          <TableCell align="right">{item["Latest Price"]}</TableCell>
          <TableCell align="right">{item["Above 10-Month Average"].toString()}</TableCell>
          <TableCell align="right">{item["Above 12-Month Risk-Free Return"].toString()}</TableCell>
          <TableCell align="right">{item["12-Month High"].toString()}</TableCell>
          <TableCell align="right">{item["10-Year High 2-Month Average"].toString()}</TableCell>
          <TableCell align="right">{item["10-Year High Price"].toString()}</TableCell>
          <TableCell align="right">{item["Health"]}</TableCell>
        </TableRow>
      );
    });
  }

  return (
    <div>
      <AppHeader />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <AppDrawer />
        <div style={{ flex: 1, margin: '1rem' }}>
          {loading ? 
            <p>Loading...</p> 
            : 
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ticker Symbol</TableCell> {/* Header for the ticker symbol column */}
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Latest Price</TableCell>
                    <TableCell align="right">Above 10-Month Average</TableCell>
                    <TableCell align="right">Above 12-Month Risk-Free Return</TableCell>
                    <TableCell align="right">12-Month High</TableCell>
                    <TableCell align="right">10-Year High 2-Month Average</TableCell>
                    <TableCell align="right">10-Year High Price</TableCell>
                    <TableCell align="right">Health</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderTableData()}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
      </div>
    </div>
  );
}

export default TAAETF;