import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import SignIn from './pages/auth/SignIn';
import Home from './pages/preclient/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import TAAETF from './pages/Dashboard/TAAETF';
import SignUp from './pages/auth/SignUp';
import Verify from './pages/auth/Verify';
import AdvisorChat from './pages/AdvisorChat/AdvisorChat';
import Goals from './pages/Profile/Goals/Goals';
import Profile from './pages/Profile/Profile';


//  TODO:  after user gets to Dashboard you should be able to use Output from React Router to navigate to other pages
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/Home" element={<Home/>}/>
        <Route path="/SignIn" element={<SignIn/>}/>
        <Route path="/SignUp" element={<SignUp/>}/>
        <Route path="/Dashboard" element={<Dashboard/>}/>
        <Route path="/TAAETF" element={<TAAETF/>}/>
        <Route path="/Profile" element={<Profile/>}/>
        <Route path="/Verify" element={<Verify/>}/>
        <Route path="/AdvisorChat" element={<AdvisorChat/>}/>
        <Route path="/Goals" element={<Goals/>}/>
      </Routes>
    </Router>
  );
}

export default App;