import client  from '../../apolloClient';
import { gql } from '@apollo/client';

const GET_ALL_GOALS = gql`
  query GetAllGoals($clientId: String!) {
    getFinancialGoals(clientId: $clientId) {
        clientId
        financialGoalId
        type
        name
        targetYear
        targetValue
        initialContribution
        monthlyContribution
        priority
    }
  }
`;

export const getFinancialGoals = async (clientId) => {
    


    const { data } = await client.query({
        query: GET_ALL_GOALS,
        variables: { clientId },
        // skip: !username,
    });

    // console.log('GraphQL Data: ', data)
    
    return data;
}