import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { TextField, Button, Typography, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CognitoConfig from "../../config/CognitoConfig";
import SiteFooter from '../../components/SiteFooter';




const SignUp = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

  const [formState, setFormState] = useState({
    username: '',
    password: '',
    email: '',
  });

  const handleFormChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
  
    const userPool = new CognitoUserPool({
      UserPoolId: CognitoConfig.UserPoolId,
      ClientId: CognitoConfig.ClientId, 
    });
  
    const attributeList = [
      new CognitoUserAttribute({ Name: 'email', Value: formState.email }),
    ];
  

    userPool.signUp(formState.username, formState.password, attributeList, null, function (err, result) {
        if (err) {
            console.log(err);
            setErrorMessage(err.message);
            return;
        } 
        var cognitoUser = result.user;
        console.log('user name is ' + cognitoUser.getUsername());
        navigate('/verify', { state: { username: formState.username } });
        localStorage.setItem('username', formState.username);
        // store email in local storage
        localStorage.setItem('email', formState.email);
    
    }) ;

  };

  const handleLoginClick = () => {
    navigate('/SignIn');
  }
  

  return (
    
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
          {/* Render Alert component when there's an error message */}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

            <Typography component="h1" variant="h5">
                Sign up
            </Typography>
            <Box component="form" onSubmit={handleSignUp} noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={formState.username}
                onChange={handleFormChange}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formState.password}
                onChange={handleFormChange}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
             
                value={formState.email}
                onChange={handleFormChange}
            />


            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSignUp}
            >   
                Sign Up
            </Button>

            <Typography component="h1" variant="h5">
                <Link onClick={handleLoginClick} variant="body2">
                    {"Already have an account? Sign In"}
                </Link>
            </Typography>

          </Box>
        </Box>
        <SiteFooter/>
        </Container>
 


    
  );
};

export default SignUp;
