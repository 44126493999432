import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import * as Yup from 'yup';
import { goalTypes } from './enums';

import NumberFormatCustom from '../../../styles/NumberFormatCustom';

import validationSchema from './ValidationSchema';

const AddGoalForm = ({ onSubmit, onCancel }) => (
    <Formik
        initialValues={{
            name: '',
            cls: '',
            targetValue: 0,
            targetYear: '',
            initialContribution: 0,
            monthlyContribution: 0,
            priority: '',
            retirementAge: 0,
            degreeType: '',
            schoolName: '',
            weddingType: '',
            realEstateType: '',
            realEstateLocation: '',
            realEstateValue: 0,
            downPayment: 0,
            mortgageTerm: 0,
            mortgageRate: 0,
            destination: '',
            durationDays: 0,
            carType: '',
            carValue: 0,
            loanTerm: 0,
            loanRate: 0,
            description: '',
        }}
        onSubmit={(values) => {
            const goalTypeSpecificValues = {
                "GrowWealthGoal": [],
                "RetirementGoal": ["retirementAge"],
                "EducationGoal": ["degreeType", "schoolName"],
                "WeddingGoal": ["weddingType"],
                "RealEstateGoal": ["realEstateType", "realEstateLocation", "realEstateValue", "mortgageTerm", "mortgageRate"],
                "VacationGoal": ["destination", "durationDays"],
                "CarGoal": ["carType", "carValue", "downPayment", "loanTerm", "loanRate"],
                "OtherGoal": ["description"],
            };

            const baseValues = ["name", "cls", "targetYear", "targetValue", "initialContribution", "monthlyContribution", "priority"];

            let finalValues = {};
            baseValues.forEach((value) => {
                finalValues[value] = values[value];
            });
            goalTypeSpecificValues[values.cls].forEach((value) => {
                finalValues[value] = values[value];
            });
            console.log(finalValues);

            onSubmit(finalValues);
        }}
        validationSchema={validationSchema}
    >
        {({ values, isSubmitting }) => (
        <Form>
            <Box marginBottom={2}>
                <Field
                    component={TextField}
                    name="name"
                    label="Name"
                    style={{ width: '500px' }}
                />
            </Box>
            <Box marginBottom={2}>
                <Field
                    component={Select}
                    name="cls"
                    label="Goal Type"
                    style={{ width: '500px' }}
                >
                    {Object.entries(goalTypes).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                    ))}
                </Field>
            </Box>
            <Box marginBottom={2}>
                <Field
                    component={TextField}
                    name="targetYear"
                    label="Target Year"
                    style={{ width: '500px' }}
                />
            </Box>
            <Box marginBottom={2}>
                <Field
                    component={TextField}
                    name="targetValue"
                    label="Target Value ($)"
                    style={{ width: '500px' }}
                    InputProps={{
                        name: "targetValue",
                        inputComponent: NumberFormatCustom,
                        inputProps: {

                        },
                    }}
                />
            </Box>
            <Box marginBottom={2}>
                <Field
                    component={TextField}
                    name="initialContribution"

                    label="Initial Contribution ($)"
                    style={{ width: '500px' }}
                    InputProps={{
                        name: "initialContribution",
                        inputComponent: NumberFormatCustom,
                        inputProps: {

                        },
                    }}
                />
            </Box>
            <Box marginBottom={2}>
                <Field
                    component={TextField}
                    name="monthlyContribution"

                    label="Monthly Contribution ($)"
                    style={{ width: '500px' }}
                    InputProps={{
                        name: "monthlyContribution",
                        inputComponent: NumberFormatCustom,
                        inputProps: {

                        },
                    }}
                />
            </Box>
            <Box marginBottom={2}>
                <Field
                    component={Select}
                    name="priority"
                    label="Priority"
                    style={{ width: '500px' }}
                    
                >
                <MenuItem value="Dreams">Dreams</MenuItem>
                <MenuItem value="Wishes">Wishes</MenuItem>
                <MenuItem value="Wants">Wants</MenuItem>
                <MenuItem value="Needs">Needs</MenuItem>
                </Field>
            </Box>
            {values.cls === "RetirementGoal" && (
                <Box marginBottom={2}>
                    <Field
                        component={TextField}
                        name="retirementAge"
                        type="number"
                        label="Retirement Age"
                        style={{ width: '500px' }}
                    />
                    <ErrorMessage name="retirementAge"/>
                </Box>
            )}
            {values.cls === "EducationGoal" && (
                <>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="degreeType"
                            label="Degree Type"
                            style={{ width: '500px' }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="schoolName"
                            label="School Name"
                            style={{ width: '500px' }}
                        />
                    </Box>
                </>
            )}
            {values.cls === "WeddingGoal" && (
                <Box marginBottom={2}>
                    <Field
                        component={TextField}
                        name="weddingType"
                        label="Wedding Type"
                        style={{ width: '500px' }}
                    />
                </Box>
            )}
            {values.cls === "RealEstateGoal" && (
                <>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="realEstateType"
                            label="Real Estate Type"
                            style={{ width: '500px' }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="realEstateLocation"
                            label="Real Estate Location"
                            style={{ width: '500px' }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="realEstateValue"

                            label="Real Estate Value ($)"
                            style={{ width: '500px' }}
                            InputProps={{
                                name: "realEstateValue",
                                inputComponent: NumberFormatCustom,
                                inputProps: {

                                },
                            }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="mortgageTerm"
                            type="number"
                            label="Mortgage Term (years)"
                            style={{ width: '500px' }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="mortgageRate"
                            type="number"
                            label="Mortgage Rate (%)"
                            style={{ width: '500px' }}
                        />
                    </Box>
                </>
            )}
            {values.cls === "VacationGoal" && (
                <>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="destination"
                            label="Destination"
                            style={{ width: '500px' }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="durationDays"
                            type="number"
                            label="Duration (days)"
                            style={{ width: '500px' }}
                        />
                    </Box>
                </>
            )}
            {values.cls === "CarGoal" && (
                <>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="carType"
                            label="Car Type"
                            style={{ width: '500px' }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="carValue"

                            label="Car Value ($)"
                            style={{ width: '500px' }}
                            InputProps={{
                                name: "carValue",
                                inputComponent: NumberFormatCustom,
                                inputProps: {

                                },
                            }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="downPayment"
       
                            label="Down Payment ($)"
                            style={{ width: '500px' }}
                            InputProps={{
                                name: "downPayment",
                                inputComponent: NumberFormatCustom,
                                inputProps: {

                                },
                            }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="loanTerm"
                            type="number"
                            label="Loan Term (years)"
                            style={{ width: '500px' }}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Field
                            component={TextField}
                            name="loanRate"
                            type="number"
                            label="Loan Rate (%)"
                            style={{ width: '500px' }}
                        />
                    </Box>
                </>
            )}
            {values.cls === "OtherGoal" && (
                <Box marginBottom={2}>
                    <Field
                        component={TextField}
                        name="description"
                        label="Description"
                        style={{ width: '500px' }}
                    />
                </Box>
            )}
           
         
            <Button type="submit" disabled={isSubmitting}>Submit</Button>
            <Button onClick={onCancel}>Cancel</Button>
        </Form>
        )}
    </Formik>
);

export default AddGoalForm;
