const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const CognitoConfig = require('../config/CognitoConfig');

export function signOut() {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: CognitoConfig.UserPoolId,
        ClientId: CognitoConfig.ClientId,
    });

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
        cognitoUser.signOut();
    }
    return;
    

}
