import React from "react";
import SiteHeader from "../../components/SiteHeader";
import Grid from '@mui/material/Grid';
import PreClient from "./PreClient";


function Home() {
  return (
    
    <div>
        <SiteHeader />
        <Grid container>
          <PreClient />
        </Grid>
       
    </div>
    

  );
}

export default Home;