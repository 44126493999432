import React from "react";

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

// create a Site Footer component

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="/">
          Zeno Financial
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}


function SiteFooter() {
    return (
        
        <Copyright sx={{ mt: 8, mb: 4 }} />
        
    );
}

export default SiteFooter;