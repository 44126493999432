


import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { getAccessToken } from './GetAccessToken';

const httpLink = new HttpLink({ uri: 'https://api.ice-ix.net/v1-graphql/graphql' });

const authLink = new ApolloLink(async (operation, forward) => {
  const accessToken = await getAccessToken();

  operation.setContext({
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
