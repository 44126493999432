import React, { useState, useEffect, useCallback, useMemo } from 'react';
import AppHeader from "../../../components/AppHeader";
import AppDrawer from '../Drawer';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

import { addGoal } from '../../../helpers/gql/goals/addGoal';
import { CircularProgress } from '@mui/material';
import { Alert } from '@mui/material';

import AddGoalForm from './AddGoalForm';
import UpdateGoalForm from './UpdateGoalForm';
import { getFinancialGoals } from '../../../helpers/gql/goals/getAllGoals';
import { goalTypes } from './enums';

import { deleteGoal } from '../../../helpers/gql/goals/deleteGoal';
import { updateGoal } from '../../../helpers/gql/goals/updateGoal';

const SortArrow = ({ order }) => (
  <span style={{ display: 'inline-block', marginLeft: '0.5rem' }}>
    {order === 'asc' ? '▲' : '▼'}
  </span>
);

function Goals() {
  const [data, setData] = useState([]);
  const [showAddGoalForm, setShowAddGoalForm] = useState(false);
  const [showUpdateGoalForm, setShowUpdateGoalForm] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clientContext, setClientContext] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = (column) => {
    if (sortBy === column) {
      // If the same column is clicked, toggle the sort order
      setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      // If a different column is clicked, set it as the new sort column with ascending order
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const sortedData = useMemo(() => {
    if (!sortBy) {
      // If no sorting is applied, return the original data
      return data;
    }

    // Sort the data based on the sort column and order
    return [...data].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      if (sortBy === 'targetValue' || sortBy === 'initialContribution' || sortBy === 'monthlyContribution') {
        // For numeric values, use subtraction instead of localeCompare
        if (sortOrder === 'asc') {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      } else {
        // For non-numeric values, use localeCompare
        if (sortOrder === 'asc') {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      }
    });
  }, [data, sortBy, sortOrder]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      // get profile from session storage
      const storedProfile = sessionStorage.getItem('customerContext');
      if (storedProfile) {
        console.log("storedProfile: " + storedProfile);
        setClientContext(JSON.parse(storedProfile));
      } else {
        setLoading(false);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(); // call fetchData when the component mounts
  }, [fetchData]); // pass fetchData as a dependency

  useEffect(() => {
    if (clientContext && clientContext.clientId) {
      const fetchGoals = async () => {
        setLoading(true);
        setError(null);

        try {
          const clientId = clientContext.clientId;
          const data = await getFinancialGoals(clientId);
          setData(data.getFinancialGoals);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchGoals();
    }
  }, [clientContext]);

  const handleAddGoalSubmit = async (values) => {
    setShowAddGoalForm(false);
    setLoading(true);
    setError(null);
    try {
      const clientId = clientContext.clientId;
      values.clientId = clientId;
      const addGoalResponse = await addGoal(values);
      setData((prevData) => [...prevData, addGoalResponse.createFinancialGoal]);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (financialGoalId) => {
    const goal = data.find((goal) => goal.financialGoalId === financialGoalId);
    // TODO: fetch the entire goal object from the API and then edit

    // Create a new object with all properties from `goal`, plus `cls`
    let newGoal = {...goal, cls: goal.type};

    setSelectedGoal(newGoal);
    console.log("Selected Goal: " + JSON.stringify(goal));
    setShowUpdateGoalForm(true);
  };

  const handleUpdateGoalSubmit = async (values) => {
    setShowUpdateGoalForm(false);
    setLoading(true);
    setError(null);
    try {
      const clientId = clientContext.clientId;

      const financialGoalId = values.financialGoalId;
      delete values.financialGoalId;
      delete values.cls;
      // call the updateGoal function

      const updateGoalResponse = await updateGoal(clientId, financialGoalId, values);
      setLoading(true);
      const data = await getFinancialGoals(clientId);
      setData(data.getFinancialGoals);

      // Update the data.
      // setData((prevData) => prevData.map((goal) => goal.financialGoalId === values.financialGoalId ? updateGoalResponse.updateFinancialGoal : goal));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleDelete = async (financialGoalId) => {
    // your logic to navigate to the delete page for this item, or open a modal, etc.
    const clientId = clientContext.clientId;
    await deleteGoal(clientId, financialGoalId);
  
    // Refresh data after deletion
    try {
      setLoading(true);
      const data = await getFinancialGoals(clientId);
      setData(data.getFinancialGoals);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  

  const onCancel = () => {
    setShowAddGoalForm(false);
    setShowUpdateGoalForm(false);
  };

  return (
    <div>
      <AppHeader />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <AppDrawer />
        <div style={{ flex: 1, margin: '1rem' }}>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : data ? (
            showAddGoalForm ? (
                <AddGoalForm onSubmit={handleAddGoalSubmit} onCancel={onCancel} />
            ) : showUpdateGoalForm ? (
                <UpdateGoalForm goal={selectedGoal} onSubmit={handleUpdateGoalSubmit} onCancel={onCancel} />
            ) : (

              <div>
                <Button onClick={() => setShowAddGoalForm(true)}>Add New Goal</Button>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>
                        Actions
                        </TableCell>

                        <TableCell style={{ fontWeight: 'bold' }} onClick={() => handleSort('name')}>
                          Goal Name {sortBy === 'name' && <SortArrow order={sortOrder} />}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }} onClick={() => handleSort('type')}>
                          Goal Type {sortBy === 'type' && <SortArrow order={sortOrder} />}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }} onClick={() => handleSort('targetYear')}>
                          Target Year {sortBy === 'targetYear' && <SortArrow order={sortOrder} />}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }} onClick={() => handleSort('targetValue')}>
                          Target Value ($) {sortBy === 'targetValue' && <SortArrow order={sortOrder} />}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }} onClick={() => handleSort('initialContribution')}>
                          Initial Contribution ($) {sortBy === 'initialContribution' && <SortArrow order={sortOrder} />}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }} onClick={() => handleSort('monthlyContribution')}>
                          Monthly Contribution ($) {sortBy === 'monthlyContribution' && <SortArrow order={sortOrder} />}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }} onClick={() => handleSort('priority')}>
                          Priority {sortBy === 'priority' && <SortArrow order={sortOrder} />}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(sortedData) &&
                        sortedData.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell align="right">
                            <Button onClick={() => handleEdit(row.financialGoalId)}>Edit</Button>
                            <Button onClick={() => handleDelete(row.financialGoalId)}>Delete</Button>
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{goalTypes[row.type]}</TableCell>
                            <TableCell align="right">{row.targetYear}</TableCell>
                            <TableCell align="right">${row.targetValue.toLocaleString()}</TableCell>
                            <TableCell align="right">${row.initialContribution.toLocaleString()}</TableCell>
                            <TableCell align="right">${row.monthlyContribution.toLocaleString()}</TableCell>
                            <TableCell align="right">{row.priority}</TableCell>
                            <input type="hidden" value={row.financialGoalId} />
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Goals;
