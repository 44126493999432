
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import { globalTheme} from './globalTheme';
import { CssBaseline } from '@mui/material';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={globalTheme}>
  <CssBaseline/>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </ThemeProvider>
);


