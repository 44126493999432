
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .required('Required')
    .matches(
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      'Invalid phone number'
    ),
  birthdate: Yup.date()
    .required('Required')
    .max(new Date(), 'Birthdate cannot be in the future'),
});




function EditForm({ initialValues, onSave, onCancel }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box marginBottom={2}>
          <Field
            component={TextField}
            name="firstName"
            label="First Name"
            style={{ width: '500px' }}
          />
          </Box>
          <Box marginBottom={2}>
          <Field
            component={TextField}
            name="lastName"
            label="Last Name"
            style={{ width: '500px' }}
          />
          </Box>
          <Box marginBottom={2}>
          <Field
            component={TextField}
            name="streetAddress"
            label="Street Address"
            style={{ width: '500px' }}
          />
          </Box>
          <Box marginBottom={2}>
          <Field
            component={TextField}
            name="city"
            label="City"
            style={{ width: '500px' }}
          />
          </Box>
          <Box marginBottom={2}>
          <Field
            component={TextField}
            name="state"
            label="State"
            style={{ width: '500px' }}
          />
          </Box>
          <Box marginBottom={2}>
          <Field
            component={TextField}
            name="zip"
            label="Zip"
            style={{ width: '500px' }}
          />
          </Box>
          <Box marginBottom={2}>
          <Field
            component={TextField}
            name="phone"
            label="Phone (XXX-XXX-XXXX)"
            style={{ width: '500px' }}
          />
          </Box>
          <Box marginBottom={2}>
          <Field
            component={TextField}
            name="birthdate"
            label="Date of Birth (MM/DD/YYYY)"
            style={{ width: '500px' }}
          />
          </Box>

          <Button type="submit" disabled={isSubmitting}>
            Save Changes
          </Button>
          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default EditForm;