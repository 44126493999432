
import React, { useState, useEffect, useCallback } from 'react'; // import useCallback
import AppHeader from "../../components/AppHeader";
import { getClientByUsername } from '../../helpers/gql/client/getClientByUsername';
import { getIdToken } from '../../helpers/GetIdToken';
import { addClient } from '../../helpers/gql/client/addClient';
import { updateClient } from '../../helpers/gql/client/updateClient';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Alert } from '@mui/material';

import Button from '@mui/material/Button';
import EditForm from './EditProfile';

import AppDrawer from './Drawer';



function Profile() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [username, setUsername] = useState('');

  const fetchData = useCallback(async () => { // use useCallback to memoize the function
    setLoading(true);
    setError(null);

    try {
      // get profile from session storage
      const storedProfile = sessionStorage.getItem('customerContext');
      if (storedProfile) {
        setData(JSON.parse(storedProfile));
        setLoading(false);
        return;
      } else {

        const idToken = await getIdToken();
        const idTokenPayload = JSON.parse(atob(idToken.split('.')[1]));

        const username = idTokenPayload['cognito:username'];
        setUsername(username);
        const email = idTokenPayload['email'];

        let data = await getClientByUsername(username);

        console.log("Data returned from getClientByUsername: "+ JSON.stringify(data))
        data = data.client;

        if (data === null) {
          console.log('No data returned');
          const result = await addClient(username, email);
          console.log("Result of adding client: "+JSON.stringify(result));
          data = result;
        } 

        setData(data);
        sessionStorage.setItem('customerContext', JSON.stringify(data));
        console.log(data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []); // pass an empty dependency array so it only creates the function once

  useEffect(() => {
    fetchData(); // call fetchData when the component mounts
  }, [fetchData]); // pass fetchData as a dependency

  const onSave = async (values) => {
    setEditing(false);
    setLoading(true);
    setError(null);
    // await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      let { firstName, lastName, phone, streetAddress, city, state, zip, birthdate } = values;
      let newObj = { firstName, lastName, phone, streetAddress, city, state, zip, birthdate };
      const updateResult = await updateClient(username, newObj);

      console.log("Result of updating client: "+JSON.stringify(updateResult));

      setData(updateResult.updateClient);
      sessionStorage.setItem('customerContext', JSON.stringify(updateResult.updateClient));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }

    // fetchData(); // call fetchData again after updating the client
  };
  
  const onCancel = () => {
    setEditing(false);
  };




  return (
    <div>
      <AppHeader />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
      <AppDrawer />

      <div style={{ flex: 1, margin: '1rem' }}>
      {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : data ? (
          editing ? (
            <EditForm initialValues={data} onSave={onSave} onCancel={onCancel} />
          ) : (
          <div>
            <Typography variant="h6" gutterBottom>Name and Email:</Typography>
            <div style={{ marginBottom: '1rem' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '150px' }}>Username</TableCell>
                    <TableCell sx={{ width: '300px' }}>Email</TableCell>
                    <TableCell sx={{ width: '150px' }}>First Name</TableCell>
                    <TableCell sx={{ width: '150px' }}>Last Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{data.username}</TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell>{data.firstName}</TableCell>
                    <TableCell>{data.lastName}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </div>

            <Typography variant="h6" gutterBottom>Home Address:</Typography>
            <div style={{ marginBottom: '1rem' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '300px' }}>Street Address</TableCell>
                    <TableCell sx={{ width: '150px' }}>City</TableCell>
                    <TableCell sx={{ width: '150px' }}>State</TableCell>
                    <TableCell sx={{ width: '150px' }}>Zip</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{data.streetAddress}</TableCell>
                    <TableCell>{data.city}</TableCell>
                    <TableCell>{data.state}</TableCell>
                    <TableCell>{data.zip}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </div>

            <Typography variant="h6" gutterBottom>Other:</Typography>
            <div style={{ marginBottom: '1rem' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '150px' }}>Phone</TableCell>
                    <TableCell sx={{ width: '150px' }}>Date of Birth</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{data.phone}</TableCell>
                    <TableCell>{data.birthdate}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </div>
            <Button onClick={() => setEditing(true)}>
              Edit Profile
            </Button>
          </div>
          )
        ) : null}
        </div>
      </div>
    </div>
  );
  
}

export default Profile;
