import client from '../../apolloClient';
import { gql } from '@apollo/client';

const ADD_GOAL = gql`

  mutation CreateFinancialGoal($input: CreateFinancialGoalInput!) { 
    createFinancialGoal(input: $input) {
            clientId 
            financialGoalId 
            type
            name
            targetYear
            targetValue
            initialContribution
            monthlyContribution
            priority
            retirementAge
            degreeType
            schoolName
            destination
            durationDays
            realEstateType
            realEstateLocation
            realEstateValue
            mortgageTerm
            mortgageRate
            carType
            carValue
            downPayment
            loanTerm
            loanRate
            weddingType
            description
        }
  }
`;

export const addGoal = async (input) => {
  if (!input) {
    throw new Error("Input required.");
  }
  // console.log("Input: " + input);


  const { data } = await client.mutate({
    mutation: ADD_GOAL,
    variables: { input },
  });

  // console.log("GraphQL Data: ", data);
  await client.resetStore();

  return data;
};
