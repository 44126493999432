import client from '../../apolloClient';
import { gql } from '@apollo/client';

const ADD_CLIENT = gql`
  mutation AddClient($input: CreateClientInput!) {
    createClient(input: $input) {
      clientId
      username
      email
      firstName
      lastName
      phone
      streetAddress
      city
      state
      zip
      birthdate
    }
  }
`;

export const addClient = async (username, email) => {
  if (!username || !email) {
    throw new Error("Username and email are required.");
  }
  // console.log("Username: " + username);
  // console.log("Email: " + email);

  const input = { username, email };

  const { data } = await client.mutate({
    mutation: ADD_CLIENT,
    variables: { input },
  });

  // console.log("GraphQL Data: ", data);

  return data;
};
