export const preClientStyles = {
    
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#000000',
        color: '#ffffff',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center'
    },banner: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '30px',
    },
    middle: {
        display: 'flex',
        flexDirection: 'row',
    },
    bottom: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '20px',
    },

};