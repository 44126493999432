import React from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';

import { preClientStyles } from './styles';
import SiteFooter from '../../components/SiteFooter';

function PreClient() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/SignIn');
  }

  const handleSignupClick = () => {
    navigate('/SignUp');
  }

  return (
    <Box sx={preClientStyles.wrapper}>
        <Box sx={preClientStyles.banner}>
            <h1>Personal Finance of the Future</h1>
        </Box>
        <Box sx={preClientStyles.middle}>
            <Button onClick={handleLoginClick}>Login</Button>
            <Button onClick={handleSignupClick}>Get Started</Button>
        </Box>
       
        <Box sx={preClientStyles.bottom}>
            <SiteFooter />
        </Box>
    </Box>
  )
}

export default PreClient
