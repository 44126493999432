import * as React from 'react';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { Authenticate } from '../../helpers/Authenticate';
import {useNavigate} from 'react-router-dom';
import SiteFooter from '../../components/SiteFooter';

import { getClientByUsername } from '../../helpers/gql/client/getClientByUsername';
import { getIdToken } from '../../helpers/GetIdToken';
import { addClient } from '../../helpers/gql/client/addClient';




export default function SignIn() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState('');
  // const [username, setUsername] = useState('');

  const fetchUser = async () => {
    const idToken = await getIdToken();
    const idTokenPayload = JSON.parse(atob(idToken.split('.')[1]));

    const username = idTokenPayload['cognito:username'];
    // setUsername(username);
    const email = idTokenPayload['email'];

    let data = await getClientByUsername(username);

    console.log("Data returned from getClientByUsername: "+ JSON.stringify(data))
    data = data.client;

    if (data === null) {
      console.log('No data returned');
      const result = await addClient(username, email);
      console.log("Result of adding client: "+JSON.stringify(result));
      data = result;
    } 

    // setData(data);
    sessionStorage.setItem('customerContext', JSON.stringify(data));
    console.log(data);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
    Authenticate(data.get('email'), data.get('password'))
      .then(async (result) => {
        console.log(result)
        try {
          // get profile from session storage
          const storedProfile = sessionStorage.getItem('customerContext');
          if (storedProfile) {
            // setData(JSON.parse(storedProfile));
            return;
          } else {
            await fetchUser();
          }
        } catch (error) {
          // setError(error.message);
          setLoading(false);
        } 
        setLoading(false);
        // if successful, redirect to the dashboard
        navigate('/Dashboard');
      })
      .catch((error) => {
        // if not successful, show error message
        setErrorMessage('Authentication failed. Please check your credentials.');
        // display error message on screen
      });

  };

  const handleSignUpClick = () => {
    navigate('/SignUp');
  }

  return (
    
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        
        {/* Render Alert component when there's an error message */}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {/* Render CircularProgress when loading */}
        {loading && <CircularProgress />}

          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24}/> : 'Sign In'} {/* Show circular progress in button when loading */}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={handleSignUpClick} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <SiteFooter/>
      </Container>
    
  );
}