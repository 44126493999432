import React from "react";
import AppHeader from "../../components/AppHeader";
import { useState, useEffect, useRef, useCallback  } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";
import Grow from "@mui/material/Grow";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";


function AdvisorChat() {

    // get clientId from session storage
    const customerContext = JSON.parse(sessionStorage.getItem('customerContext'));
    const clientId = customerContext.clientId;

    const advisorUrl = process.env.REACT_APP_FE_WS_BASE_URL;
    // const advisorEndpoint = process.env.REACT_APP_MS_ADVISOR_ENDPOINT;


    const [message, setMessage] = useState('');
    const [conversationId, setConversationId] = useState('');
    const [messages, setMessages] = useState([]);
    const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);
    const [outgoingMessages, setOutgoingMessages] = useState([]);

    const socketRef = useRef(null); 
    const unmountedRef = useRef(false);


    const sendOutgoingMessages = useCallback(() => {
      while (outgoingMessages.length > 0 && socketRef.current.readyState === WebSocket.OPEN) {
        const message = outgoingMessages.shift();
        socketRef.current.send(JSON.stringify(message));
      }
    }, [outgoingMessages]);


    // set conversationId if found in session storage
    useEffect(() => {
      const storedConversationId = sessionStorage.getItem('conversationId');
      setConversationId(storedConversationId ? storedConversationId : '');
    }, []);
    
    // function to initialize WebSocket connection
    const initWebSocket = useCallback(() => {
      console.log('Initializing WebSocket connection...');
      socketRef.current = new WebSocket(advisorUrl);
    
      socketRef.current.onopen = () => {
        console.log('WebSocket is open now.');
        setIsWebSocketOpen(true);
        sendOutgoingMessages();
      };
      
      socketRef.current.onmessage = (event) => {
        // handle incoming messages
        const response = JSON.parse(event.data);
        console.log(event);
        console.log(response);
        const receivedConversationId = response.response.conversationId;
        console.log(receivedConversationId);
        
        sessionStorage.setItem('conversationId', receivedConversationId);
        setConversationId(receivedConversationId);
      
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: "advisor", content: response.response.message },
        ]);
      };
    
      socketRef.current.onclose = () => {
        //console.log('WebSocket is closed now. Reconnecting...');
        setIsWebSocketOpen(false);
        if (!unmountedRef.current) {
          // reconnect if the component is still mounted
          setTimeout(initWebSocket, 1000);
        }
      };
    }, [advisorUrl, sendOutgoingMessages]);
    
    


    useEffect(() => {
      initWebSocket(); // initialize WebSocket when component mounts
  
      return () => {
        unmountedRef.current = true;
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }, [initWebSocket]);


    
    

    const getAdvisorChat = useCallback( () => {
      try {
        const messageCopy = message;
        setMessage('');
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: "user", content: messageCopy },
        ]);



  
        const data = {
          action: 'fin-advisor',
          clientId: clientId,
          conversationId: conversationId,
          message: messageCopy,
        };
        setOutgoingMessages([...outgoingMessages, data]);

          
        if (!isWebSocketOpen) {
          console.error('2: WebSocket connection is not open. Reconnecting...');
          initWebSocket();
        }
        

      }
      catch (err) {
        console.error(err.message);
      }
    }, [clientId, conversationId, initWebSocket, isWebSocketOpen, message, outgoingMessages]);
    

      

    return (
        <div>
          <AppHeader />
          <Container maxWidth="md">
            <Grid container justifyContent="left" alignItems="left">
                <Grid item xs={12} sm={12}>

                <Box my={4} textAlign="left">
                <Typography variant="h4">Chat with a Zeno Advisor</Typography>
                <Paper
                    elevation={2}
                    style={{
                        maxHeight: "60vh",
                        overflowY: "auto",
                        paddingRight: "8px",
                        paddingBottom: "80px",
                        marginTop: "16px",
                        marginBottom: "80px", // Add marginBottom here
                    }}
                    >

                    <Box pb={10}> {/* Add this Box with paddingBottom */}
                    {messages.map((msg, idx) => (
                        <Box key={idx} mt={1}>
                        <Typography variant="body1">
                            <strong>{msg.type === "user" ? "You: " : "Advisor: "}</strong>
                            {msg.content}
                        </Typography>
                        </Box>
                    ))}
                    </Box>
                </Paper>
                </Box>



                </Grid>
            </Grid>
            <Grow in style={{ transformOrigin: "0 0 0" }} timeout={1000}>
              <AppBar
                position="fixed"
                color="inherit"
                style={{
                    top: "auto",
                    bottom: 0,
                    background: "transparent",
                    boxShadow: "none",
                    zIndex: -1, // Add this zIndex
                }}
                >

                <Box
                  component="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    getAdvisorChat();
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  py={2}
                >
                  <TextField
                    label="Message"
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ width: "60%" }}
                    />
                </Box>
              </AppBar>
            </Grow>
          </Container>
        </div>
      );
      
      
}

export default AdvisorChat;