import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: parseFloat(values.value.replace(/,/g, '')),
          },
        });
      }}
      name={name}
      thousandSeparator
      isNumericString
      prefix="$"
      onInput={(e) => {
        // prevent entering multiple dots
        e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
      }}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default NumberFormatCustom;
