import React, { useEffect, useState } from 'react'; 
import AppHeader from "../../components/AppHeader";
import Plot from 'react-plotly.js';

import AppDrawer from './DashboardDrawer';

function Dashboard() {

  const [gdpData, setGdpData] = useState({ x: [], y: [] });
  const [loading, setLoading] = useState(true); // Initialize loading state

  useEffect(() => {
    getGDPData();
  }, []);

  const getGDPData = async () => {
    const api_call = await fetch('https://api.ice-ix.net/v1-aiae-indicator/aiae', {
      mode: 'cors',

    })
    const data = await api_call.json();
    // console.log(data);

    const xData = data.map(obs => obs.index); 
    const yData = data.map(obs => parseFloat(obs.value)); 
    
    setGdpData({ x: xData, y: yData });
    setLoading(false); // Set loading to false when data is fetched
  }

  const plotData = [
    {
      x: gdpData.x,
      y: gdpData.y,
      type: 'scatter',
      mode: 'lines',
      marker: {color: 'red'},
      line: {color: '#7F7F7F'}
    }
  ];

  const plotLayout = {
    title: 'AIAE Data',
    width: 600,
    height: 400,
  };

  return (
    <div>
      <AppHeader />
      
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <AppDrawer />
        <div style={{ flex: 1, margin: '1rem' }}>
          {loading ? 
            <p>Loading...</p> 
            : 
            <div>
              <Plot
                data={plotData}
                layout={plotLayout}
              />
              <p>
                Description: This indicator, called the Aggregate Investor Allocation to Equities (AIAE), 
                has been further analyzed by Raymond Micaletti in his paper Towards a Better 
                Fed Model, with the conclusion that it indeed has superior equity-return 
                forecasting ability compared to other well-known indicators (such as the 
                CAPE ratio, Tobin’s Q, Market Cap-to-GDP, etc.).
              </p>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
