import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { signOut } from '../helpers/SignOut';
import {ReactComponent as ZFLogo} from '../assets/ZFLogo.svg';


export default function AppHeader() {
    const navigate = useNavigate();

    const handleClickSignoff = () => {
        signOut();
        navigate('/Home');
    }

    const handleClickAdvisor = () => {
      navigate('/AdvisorChat');
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <ZFLogo width="50px" height="50px" />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: '16px' }}>
                        Zeno Financial
                    </Typography>
                    <Button color="inherit" onClick={() => navigate('/Dashboard')}>Dashboard</Button>
                    <Button color="inherit" onClick={handleClickAdvisor}>Advisor</Button>
                    <Button color="inherit" onClick={() => navigate('/Profile')}>Profile</Button>
                    <Button color="inherit" onClick={handleClickSignoff}>Signoff</Button>
                </Toolbar>
            </AppBar>
            <Toolbar /> {/* This pushes the content down underneath the AppBar */}
            
        </Box>
    );
}
