// enums.js
export const goalTypes = {
    GrowWealthGoal: 'Grow Wealth Goal',
    RetirementGoal: 'Retirement Goal',
    EducationGoal: 'Education Goal',
    WeddingGoal: 'Wedding Goal',
    RealEstateGoal: 'Real Estate Goal',
    VacationGoal: 'Vacation Goal',
    CarGoal: 'Car Goal',
    OtherGoal: 'Other Goal',
  };
  
  export const typeToClassMapping = {
    "Grow Wealth Goal": "GrowWealthGoal",
    "Retirement Goal": "RetirementGoal",
    "Education Goal": "EducationGoal",
    "Wedding Goal": "WeddingGoal",
    "Real Estate Goal": "RealEstateGoal",
    "Vacation Goal": "VacationGoal",
    "Car Goal": "CarGoal",
    "Other Goal": "OtherGoal",
};