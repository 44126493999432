import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as ZFLogo} from '../assets/ZFLogo.svg';

export default function SiteHeader() {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/SignIn');
    }

    const handleSignupClick = () => {
      navigate('/SignUp');
    }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <ZFLogo width="50px" height="50px" />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: '16px' }}>
            Zeno Financial
          </Typography>
          <Button color="inherit" onClick={handleLoginClick}> Login </Button>
          <Button color="inherit" onClick={handleSignupClick}> Get Started </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
