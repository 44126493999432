import {
    CognitoUser,
    AuthenticationDetails,
    CognitoUserPool,
  } from "amazon-cognito-identity-js";

import CognitoConfig from "../config/CognitoConfig";


export const Authenticate = (email, password) => {
    return new Promise((resolve, reject) => {
        const userPool = new CognitoUserPool({
            UserPoolId: CognitoConfig.UserPoolId,
            ClientId: CognitoConfig.ClientId, 
        });

        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {

                resolve({ success: true });
            },
            onFailure: (err) => {
                reject({ success: false, error: err });
            }
        });

    });
};


