import client from '../../apolloClient';
import { gql } from '@apollo/client';

const UPDATE_CLIENT = gql`
    mutation UpdateClient($username: String!, $input: UpdateClientInput!) {
    updateClient(username: $username, input: $input) {
      clientId username email firstName lastName phone streetAddress city state zip birthdate
    }
  }
`;

export const updateClient = async (username, input) => {
    if (!username || !input) {
        throw new Error("Username and input are required.");
    }
    // console.log("Username: " + username);
    // console.log("Input: " + input);

    const { data } = await client.mutate({
        mutation: UPDATE_CLIENT,
        variables: { username, input },
    });

    // console.log("GraphQL Data: ", data);

    
  

  return data;
};