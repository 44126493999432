import { createTheme } from '@mui/material/styles';


// MUI theme creator: 
// https://mui.com/customization/default-theme/
// https://zenoo.github.io/mui-theme-creator/
export const globalTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
          main: '#648be2',
        },
        secondary: {
          main: '#9c27b0',
        },
      },
  });

